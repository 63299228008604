import React from "react";
import { ContainerSection } from "./styles";
import { PropsMedicamentosRecipe } from "./interface";

const Medicamentos = ({ medicamentos, imprimirDiagnostico }: PropsMedicamentosRecipe) => {

  const getColor = (id: number) => {
    if (id === 1) {
      return "#43AF52";
    } else if (id === 2) {
      return "#FF1B1B";
    } else {
      return "#0088D1";
    }
  }

  const validateParamImprimirDiagnostico = (value: string, diagnostico: string) => {
    if(value === "N"){
      return(" - ")
    }
    return(diagnostico)
  }
  return (
    <ContainerSection>
      <p className="Title">Medicamentos</p>
      {medicamentos.map((med, index) => {
          return (
            <div className="MedicamentoContent" key={index}>
              <div className="MedicamentoPrescipto">
                <div className="LineaHeader">
                  <p className="Subtitle">Rp/</p>
                </div>
                {med.estado && (
                  <div className="BarStatus">
                    <div className="Status">
                      <div
                        className="Indicador"
                        style={{ background: getColor(med.estado.id) }}
                      />
                      <p style={{ color: getColor(med.estado.id) }}>
                        {med.estado.descripcion}
                      </p>
                    </div>
                  </div>
                )}
                <div className="BodyMed">
                  {/*med.permiteSustitucion !== "S" && (
                    <div className="LineaDescripcion">
                      <p>{med.nombreProducto}</p>
                    </div>
                  )*/}
                  <div className="LineaDescripcion">
                    <p>{med.nombreProducto}</p>
                  </div>
                  <div className="LineaDescripcion">
                    <p>{med.nombreDroga}</p>
                  </div>
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Presentación: </span>
                      {med.presentacion}
                    </p>
                  </div>
                  {med.permiteSustitucion === "N" && (
                    <div className="LineaDescripcion">
                      <p>NO SUSTITUIR</p>
                    </div>
                  )}
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Cantidad: </span>
                      {med.cantidad}
                    </p>
                  </div>
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Potencia: </span>
                      {med.potencia}
                    </p>
                  </div>
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Forma: </span>
                      {med.formaFarmaceutica}
                    </p>
                  </div>
                </div>

                <div className="GroupLineaDescripcion">
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Código CIE-10 / Diagnóstico:</span>{" "}
                      {med.codDiagnostico ? med.codDiagnostico : " - "} 
                      {" / "}
                      {med.diagnostico ? validateParamImprimirDiagnostico( imprimirDiagnostico, med.diagnostico) : " - "}
                    </p>
                  </div>
                  <div className="LineaDescripcion">
                    <p>
                      <span className="Tag">Tratamiento prolongado:</span>{" "}
                      {med.tratamiento === 0 ? "No" : "Sí"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
      })}
    </ContainerSection>
  );
};

export default Medicamentos;
